import * as React from 'react';
import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import MarketMapGGVarsQuery from '@graphql/queries/MarketMapGGVarsQuery';
import MarketMapLdaQuery from '@graphql/queries/MarketMapLdaQuery';
import MarketMapProductsQuery from '@graphql/queries/MarketMapProductsQuery';
import MarketMapRefFlavorsQuery from '@graphql/queries/MarketMapRefFlavorsQuery';
import { Box } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import {
  ProductVersion,
  ProductVersionSet,
} from 'components/Report/ProductVersion';
import useCustomProductNames from 'hooks/useCustomProductNames';
import useGgEnvironment from 'hooks/useGgEnvironment';
import useReportSummary, { ProductVersionPq } from 'hooks/useReportSummary';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import selectViewerUserId from 'selectors/viewerUserId';
import {
  CustomerPreferences,
  useCustomerPreferences,
} from 'services/customerPreferences';

import InsufficientDataMap from './InsufficentDataMap';
import { rotateToDiagonal } from './layers/layerUtils';
import MarketMap from './MarketMap';
import MarketMapExplainer from './MarketMapExplainer';
import MarketMapRefresh from './MarketMapRefresh';
import { getProduct, getProductLabel, getProductName } from '../../utils';
import { MapOptions, setRotationAngle } from '../MapOptions';
import { mapLabelVersion } from '../mapUtils';

interface ReportProps {
  projectId?: number;
  reportId: string;
  parentReportId?: string; // if provided, display products from both reports
  showLegend: boolean;
  showTitle: boolean;
  excludedProducts: ProductVersionSet;
  flavorMapOptions: MapOptions;
  setFlavorMapOptions: (newMapOptions: MapOptions) => void;
  pageUpdated?: boolean;
  workspaceId?: number;
  userId?: number;
  setExcludedProducts: React.Dispatch<React.SetStateAction<ProductVersionSet>>;
}

interface ProductPq {
  productId: number;
  productVersion: string;
  pq: string;
}

const ReportMarketMap: React.FC<ReportProps> = React.memo((props) => {
  const {
    showTitle,
    showLegend,
    projectId,
    reportId,
    parentReportId,
    excludedProducts,
    flavorMapOptions,
    setFlavorMapOptions,
    pageUpdated,
    workspaceId,
    userId,
    setExcludedProducts,
  } = props;

  const { t } = useTranslation();

  const viewerUserId = useSelector((state) => selectViewerUserId(state));
  const [explainerModalOpen, setExplainerModalOpen] = useState<boolean>(false);

  const customProductNames = useCustomProductNames({
    projectId,
    reportId,
    refetchNames: pageUpdated,
  });
  const environment = useGgEnvironment();

  const customerPreferences = useCustomerPreferences();

  const {
    loading: summaryLoading,
    error: summaryError,
    productVersionSet,
    productVersionPqs,
  } = useReportSummary(reportId);

  const {
    loading: productsLoading,
    error: productsError,
    data: marketMapProductsResponse,
  } = useQuery<reports.MarketMapProductsResponse>(MarketMapProductsQuery, {
    variables: {
      reportID: reportId,
    },
  });

  const {
    loading: parentSummaryLoading,
    error: parentSummaryError,
    data: parentReportSummary,
    productVersionSet: parentProductVersionSet,
    productVersionPqs: parentProductVersionPqs,
  } = useReportSummary(parentReportId, {
    skip: !parentReportId,
  });

  const {
    loading: parentProductsLoading,
    error: parentProductsError,
    data: parentProductsResponse,
  } = useQuery<reports.MarketMapProductsResponse>(MarketMapProductsQuery, {
    variables: {
      reportID: parentReportId,
    },
    skip: !parentReportId,
  });

  const {
    loading: ldasLoading,
    error: ldasError,
    data: marketMapLdasResponse,
  } = useQuery<reports.MarketMapLdasResponse>(MarketMapLdaQuery, {
    variables: {
      reportID: reportId,
    },
  });

  const {
    loading: ggVarsLoading,
    error: ggVarsError,
    data: marketMapGGVarsResponse,
  } = useQuery<reports.MarketMapGGVarsResponse>(MarketMapGGVarsQuery, {
    variables: {
      reportID: reportId,
    },
  });

  const {
    loading: refFlavorsLoading,
    error: refFlavorsError,
    data: marketMapRefFlavorsResponse,
  } = useQuery<reports.MarketMapRefFlavorResponse>(MarketMapRefFlavorsQuery, {
    variables: {
      reportID: reportId,
    },
  });

  useEffect(() => {
    if (marketMapGGVarsResponse && flavorMapOptions.rotationPristine) {
      setFlavorMapOptions(
        setRotationAngle(
          flavorMapOptions,
          rotateToDiagonal(reshapeGGVarArray(marketMapGGVarsResponse)),
        ),
      );
    }
  }, [marketMapGGVarsResponse]);

  const detemineProductRMax = (
    marketMapProductsResponse: reports.MarketMapProductsResponse,
  ) => {
    return marketMapProductsResponse?.allRpMarketmapProductvectors?.nodes
      .map((v) => Math.sqrt(Number(v.ld1) ** 2 + Number(v.ld2) ** 2))
      .reduce(
        (acc: number, val: number) =>
          acc === undefined || val > acc ? val : acc,
        undefined,
      );
  };

  const reshapeProductVectorArray = (
    customerPreferences: CustomerPreferences,
    marketMapProductsResponse: reports.MarketMapProductsResponse,
    productVersionSet: ProductVersionSet,
    productVersionPqs: ProductVersionPq[],
    reportId?: string,
    productLabelOffset?: number,
  ): marketmap.ProductVectorData[] => {
    return marketMapProductsResponse?.allRpMarketmapProductvectors?.nodes
      .filter(
        (n) =>
          !!n.product &&
          productVersionSet.has(new ProductVersion(n.product.id, n.version)),
      )
      .sort((a, b) => parseFloat(b.pq) - parseFloat(a.pq))
      .map((n, idx) => ({
        productId: n.product.id,
        reportId: reportId,
        name: getProductName({
          productNames: customProductNames,
          productId: n.product.id,
          version: n.version,
        }),
        version: n.version,
        ld1: parseFloat(n.ld1),
        ld2: parseFloat(n.ld2),
        pq: productVersionPqs.find((i) =>
          i.productVersion.equals(new ProductVersion(n.product.id, n.version)),
        )?.pq,
        idx,
        label:
          getProductLabel({
            productNames: customProductNames,
            productId: n.product.id,
            version: n.version,
          }) ||
          mapLabelVersion(
            customerPreferences,
            productVersionSet,
            new ProductVersion(n.product.id, n.version),
            productLabelOffset,
          ),
        productLabelColor: getProduct({
          productNames: customProductNames,
          productId: n.product.id,
          version: n.version,
        })
          .map((p) => p.productLabelColor)
          .orNull(),
      }));
  };

  const reshapeLdasArray = (
    marketMapLdasResponse: reports.MarketMapLdasResponse,
  ): marketmap.HeatMapData[] => {
    return marketMapLdasResponse?.allRpMarketmapLdas?.nodes.map(
      (v: reports.MarketMapLdas) => ({
        pixelId: v.rowid,
        productId: null,
        ld1: parseFloat(v.ld1),
        ld2: parseFloat(v.ld2),
        pq: parseFloat(v.pq),
        r: null,
        ld1r: null,
        ld2r: null,
      }),
    );
  };

  const reshapeHeatMapArray = (
    marketMapProductsResponse: reports.MarketMapProductsResponse,
  ): marketmap.HeatMapData[] => {
    return marketMapProductsResponse?.allRpMarketmapProductvectors?.nodes.map(
      (v: reports.MarketMapProductvector) => ({
        pixelId: null,
        productId: v?.product?.id,
        ld1: parseFloat(v.ld1),
        ld2: parseFloat(v.ld2),
        pq: parseFloat(v.pq),
        r: null,
        ld1r: null,
        ld2r: null,
      }),
    );
  };

  const reshapeGGVarArray = (
    marketMapGGVarsResponse: reports.MarketMapGGVarsResponse,
  ): marketmap.LabelData[] => {
    return marketMapGGVarsResponse.allRpMarketmapGgvarvectors.nodes.map(
      (item: reports.MarketMapGGVarVector) => ({
        label: item.ggvar,
        angle: parseFloat(item.angle),
        ld1: parseFloat(item.ld1),
        ld2: parseFloat(item.ld2),
      }),
    );
  };

  const reshapeRefFlavorArray = (
    marketMapRefFlavorResponse: reports.MarketMapRefFlavorResponse,
  ): marketmap.LabelData[] => {
    return marketMapRefFlavorResponse.allRpMarketmapRfvectors.nodes.map(
      (item: reports.MarketMapRefFlavor) => ({
        label: item.referenceflavor,
        angle: parseFloat(item.angle),
        ld1: 0,
        ld2: 0,
      }),
    );
  };

  const isInArray = (
    acc: marketmap.ProductVectorData[],
    cur: marketmap.ProductVectorData,
  ) => {
    if (acc.length) {
      return acc
        .map((i) => i.productId == cur.productId && i.version === cur.version)
        .reduce((a, b) => a || b);
    } else {
      return false;
    }
  };

  if (
    summaryLoading ||
    summaryError ||
    productsLoading ||
    productsError ||
    ldasLoading ||
    ldasError ||
    ggVarsLoading ||
    ggVarsError ||
    refFlavorsLoading ||
    refFlavorsError ||
    (parentReportId &&
      (parentProductsLoading ||
        parentProductsError ||
        parentSummaryLoading ||
        parentSummaryError))
  ) {
    return <LoadingScreen />;
  }

  const productRMax = parentProductsResponse
    ? Math.max(
        detemineProductRMax(marketMapProductsResponse),
        detemineProductRMax(parentProductsResponse),
      )
    : detemineProductRMax(marketMapProductsResponse);
  const productVectorArray = reshapeProductVectorArray(
    customerPreferences,
    marketMapProductsResponse,
    productVersionSet,
    productVersionPqs,
  )
    .concat(
      parentProductsResponse
        ? reshapeProductVectorArray(
            customerPreferences,
            parentProductsResponse,
            parentProductVersionSet,
            parentProductVersionPqs,
            parentReportId,
            marketMapProductsResponse?.allRpMarketmapProductvectors?.nodes.filter(
              (n) =>
                !!n.product &&
                productVersionSet.has(
                  new ProductVersion(n.product.id, n.version),
                ),
            ).length,
          )
        : [],
    )
    // Filter out duplicates
    .reduce(
      (acc: marketmap.ProductVectorData[], cur: marketmap.ProductVectorData) =>
        isInArray(acc, cur) ? acc : acc.concat(cur),
      [],
    );
  const ldasArray = reshapeLdasArray(marketMapLdasResponse);
  const heatmapArray = reshapeHeatMapArray(marketMapProductsResponse);
  const ggVarArray = reshapeGGVarArray(marketMapGGVarsResponse);
  const refFlavorArray = reshapeRefFlavorArray(marketMapRefFlavorsResponse);

  if (productVectorArray.length == 0 || heatmapArray.length == 0) {
    return <InsufficientDataMap />;
  } else {
    return (
      <div>
        {showTitle && <h4>{t('reports.marketMap.title')}</h4>}

        {/* Add refresh and explainer icons */}
        <Box display="flex" justifyContent="right">
          <MarketMapRefresh
            mapOptions={flavorMapOptions}
            setMapOptions={setFlavorMapOptions}
          />
          <MarketMapExplainer
            explainerModalOpen={explainerModalOpen}
            setExplainerModalOpen={setExplainerModalOpen}
          />
        </Box>

        {/* Actual map object */}
        <MarketMap
          isProd={environment === 'production'}
          productRMax={productRMax}
          productVectorArray={productVectorArray}
          ggVarArray={ggVarArray}
          refFlavorArray={refFlavorArray}
          heatmapArray={ldasArray.concat(heatmapArray)}
          contourArray={heatmapArray}
          excludedProducts={excludedProducts}
          projectId={projectId}
          reportId={reportId}
          viewerUserId={viewerUserId}
          workspaceId={workspaceId}
          userId={userId}
          allowPixelRequest={true}
          granularity={800}
          contourThreshold={10}
          contourBandwidth={25}
          mapOptions={flavorMapOptions}
          setMapOptions={setFlavorMapOptions}
          setExcludedProducts={setExcludedProducts}
        />

        {showLegend &&
          [...productVectorArray]
            .sort((a, b) => a.idx - b.idx)
            .map((pv, key) => (
              <div key={key}>
                {pv.label}: {pv.name}
              </div>
            ))}
      </div>
    );
  }
});

export default ReportMarketMap;
