import * as React from 'react';

import { useLazyQuery } from '@apollo/client';
import GetReportDownloadUrl from '@graphql/queries/GetReportDownloadUrl';
import { PictureAsPdf, CloudDownload } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export function downloadPDF(event: React.MouseEvent, url) {
  // from https://codepen.io/Alexander9111/pen/VwLaaPe?editors=1010
  //get svg element.
  const a = document.createElement('a');
  const e = new MouseEvent('click');
  a.download = 'download.pdf';
  a.href = url;
  a.dispatchEvent(e);
}

interface ReportProps {
  reportId: string;
  pdfIcon?: boolean;
}

const ReportDownloadIcon: React.FC<ReportProps> = (props) => {
  const { reportId, pdfIcon = false } = props;

  const [getReport] = useLazyQuery(GetReportDownloadUrl);

  const handleClick = async (e: React.MouseEvent, reportId: string) => {
    const reportUrl = await getReport({
      variables: {
        reportId: reportId,
      },
    });
    downloadPDF(e, reportUrl.data.reportPdfUrl);
  };

  if (reportId === null) {
    return null;
  }

  return (
    <IconButton
      id="pdf-report-link"
      size="small"
      onClick={(e) => handleClick(e, reportId)}
    >
      {pdfIcon ? (
        <PictureAsPdf fontSize="medium" color="primary" />
      ) : (
        <CloudDownload fontSize="medium" color="primary" />
      )}
    </IconButton>
  );
};

export default ReportDownloadIcon;
