export const NOTIFICATION_TYPE_ENUM = {
  QUICK_CREATE_PRODUCT: 'QUICK_CREATE_PRODUCT',
  COMPLETED_PANEL: 'COMPLETED_PANEL',
  MARKET_SURVEY_REPORT_READY: 'MARKET_SURVEY_REPORT_READY',
  OPTIMIZATION_REPORT_READY: 'OPTIMIZATION_REPORT_READY',
};

export const ROLE_ENUM = {
  GASTROGRAPH_ADMIN: 'GASTROGRAPH_ADMIN',
  GASTROGRAPH_SUPERADMIN: 'GASTROGRAPH_SUPERADMIN',
  GASTROGRAPH_USER: 'GASTROGRAPH_USER',
  GASTROGRAPH_ANONYMOUS: 'GASTROGRAPH_ANONYMOUS',
};
