import { gql } from '@apollo/client';

export interface UserNotificationsResponse {
  loading: boolean;
  error: any;
  notifications: {
    nodes: NotificationNode[];
  };
}

export interface NotificationNode {
  id: number;
  active: boolean;
  readOn: string;
  notificationTypeId: number;
  userId: number;
  producerId: number;
  user: {
    id: number;
    name: string;
  };
  notificationType: {
    id: number;
    notificationType: string;
    productId: number;
    product: {
      id: number;
      name: string;
      createdAt: string;
      updatedAt: string;
    };
    panelId: number;
    panel: {
      id: number;
      pin: string;
    };
    reportId: string;
    report: {
      projectName: string;
      clientName: string;
      reportType: string;
    };
    role: string;
    sentOn: string;
  };
}

export default gql`
  query UserNotificationsQuery($producerId: Int!, $active: Boolean) {
    notifications: allNotifications(
      condition: { producerId: $producerId, active: $active }
    ) {
      nodes {
        id
        active
        readOn
        notificationTypeId
        userId
        producerId
        user: userByUserId {
          id
          name
        }
        notificationType: notificationTypeByNotificationTypeId {
          id
          notificationType
          productId
          product: productByProductId {
            id
            name
            createdAt
            updatedAt
          }
          panelId
          panel: panelByPanelId {
            id
            pin
          }
          reportId
          report: reportJobByReportId {
            projectName
            clientName
            reportType
          }
          role
          sentOn
        }
      }
    }
  }
`;
