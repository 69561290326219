import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { flowRight as compose } from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import SelectWorkspaceContainer from './SelectWorkspaceContainer';
import appWorkspaceMenuIsOpenSet from '../../actions/appWorkspaceMenuIsOpenSet';
import productFolderIdSet from '../../actions/productFolderIdSet';
import workspaceProducerIdSet from '../../actions/workspaceProducerIdSet';
import LoadingWrapper from '../../components/LoadingWrapper';
import { PRODUCTS } from '../../constants/routePaths';
import ViewerQuery from '../../graphql/queries/ViewerQuery';
import selectAppWorkspaceMenuIsOpen from '../../selectors/appWorkspaceMenuIsOpen';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';

const mapStateToProps = (state) => ({
  menuIsOpen: selectAppWorkspaceMenuIsOpen(state),
  selectedWorkspaceKey: selectWorkspaceProducerId(state),
});

const actionCreators = {
  onClickCloseMenu: () => appWorkspaceMenuIsOpenSet(false),
  onClickOpenMenu: () => appWorkspaceMenuIsOpenSet(true),
  onChangeSelectedWorkspaceId: workspaceProducerIdSet,
  setProductFolderId: productFolderIdSet,
};

export default compose(
  connect(mapStateToProps, actionCreators),
  graphql<any, any, any, any>(ViewerQuery, {
    props: ({
      data: { loading, viewer },
      ownProps: { onChangeSelectedWorkspaceId, setProductFolderId },
    }) => {
      const workspaceProducers = viewer && viewer.workspaceProducers;
      const workspaceProducerNodes =
        workspaceProducers && workspaceProducers.nodes;
      const workspaceKeys =
        workspaceProducerNodes &&
        [...workspaceProducerNodes]
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map(({ id }) => id);
      const workspaceProducerNamesByWorkspaceKey: Map<number, string> =
        workspaceProducerNodes &&
        workspaceProducerNodes.reduce(
          (aggregateValue, { id, name }) => ({
            ...aggregateValue,
            [id]: name,
          }),
          {},
        );

      return {
        loading,
        workspaceKeys,
        workspaceProducerNamesByWorkspaceKey,
        renderWorkspaceLink: ({ children, workspaceKey }) => (
          <Link
            to={PRODUCTS}
            key={workspaceKey}
            onClick={() => {
              setProductFolderId(0);
              return onChangeSelectedWorkspaceId({
                key: workspaceKey,
                name: workspaceProducerNamesByWorkspaceKey[workspaceKey],
              });
            }}
            tabIndex={-1}
          >
            {children}
          </Link>
        ),
        renderWorkspaceOption: ({ workspaceKey, classes }) => {
          const workspaceProducerName =
            workspaceProducerNamesByWorkspaceKey[workspaceKey];

          return (
            <ListItem classes={{ root: classes.root }} button>
              <ListItemAvatar>
                <Avatar classes={{ root: classes.avatar }}>
                  {workspaceProducerName.charAt(0)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                classes={{ primary: classes.primary }}
                primary={workspaceProducerName}
              />
            </ListItem>
          );
        },
      };
    },
  }),
)((props) => (
  <LoadingWrapper {...props} Component={SelectWorkspaceContainer} />
));
