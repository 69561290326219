import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { flowRight as compose } from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import SwapWorkspaceIcon from './SwapWorkspaceIcon';
import appWorkspaceMenuIsOpenSet from '../../actions/appWorkspaceMenuIsOpenSet';
import changeProductTablePage from '../../actions/changeProductTablePage';
import productFolderIdSet from '../../actions/productFolderIdSet';
import workspaceProducerIdSet from '../../actions/workspaceProducerIdSet';
import AppWorkspaceMenu from '../../components/AppWorkspaceMenu';
import LoadingWrapper from '../../components/LoadingWrapper';
import { PRODUCTS } from '../../constants/routePaths';
import ViewerQuery from '../../graphql/queries/ViewerQuery';
import selectAppWorkspaceMenuIsOpen from '../../selectors/appWorkspaceMenuIsOpen';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';

const mapStateToProps = (state) => ({
  menuIsOpen: selectAppWorkspaceMenuIsOpen(state),
  selectedWorkspaceKey: selectWorkspaceProducerId(state),
});

const actionCreators = {
  onClickCloseMenu: () => appWorkspaceMenuIsOpenSet(false),
  onClickOpenMenu: () => appWorkspaceMenuIsOpenSet(true),
  onChangeSelectedWorkspaceId: workspaceProducerIdSet,
  setProductFolderId: productFolderIdSet,
  setProductTablePage: changeProductTablePage,
  // addDefaultProductClass
};

interface ViewerData {
  loading: boolean;
  viewer: {
    workspaceProducers: {
      nodes: any[];
    };
  };
}

export default compose(
  connect(mapStateToProps, actionCreators),
  graphql<any, ViewerData, any, any>(ViewerQuery, {
    props: ({
      data: { loading, viewer },
      ownProps: {
        onChangeSelectedWorkspaceId,
        // addDefaultProductClass,
        setProductFolderId,
        setProductTablePage,
      },
    }) => {
      const workspaceProducers = viewer && viewer.workspaceProducers;
      const workspaceProducerNodes =
        workspaceProducers && workspaceProducers.nodes;
      const workspaceKeys =
        workspaceProducerNodes && workspaceProducerNodes.map(({ id }) => id);
      const workspaceProducerNamesByWorkspaceKey: Map<number, string> =
        workspaceProducerNodes &&
        workspaceProducerNodes.reduce(
          (aggregateValue, { id, name }) => ({
            ...aggregateValue,
            [id]: name,
          }),
          {},
        );

      return {
        loading,
        workspaceKeys,
        workspaceProducerNamesByWorkspaceKey,
        renderWorkspaceLink: ({ children, workspaceKey, clearSearch }) => (
          <Link
            to={PRODUCTS}
            key={workspaceKey}
            onClick={() => {
              // addDefaultProductClass(workspaceKey);
              clearSearch();
              setProductFolderId(0);
              setProductTablePage(1);
              return onChangeSelectedWorkspaceId({
                key: workspaceKey,
                name: workspaceProducerNamesByWorkspaceKey[workspaceKey],
              });
            }}
            tabIndex={-1}
          >
            {children}
          </Link>
        ),
        renderCurrentWorkspace: ({
          workspaceKey,
          workspaceOptionClassName,
          workspaceCurrentTextClassName,
        }) => {
          const workspaceProducerName =
            workspaceProducerNamesByWorkspaceKey &&
            workspaceProducerNamesByWorkspaceKey[workspaceKey];
          const theme = useTheme();

          return (
            <div className={workspaceOptionClassName} key={workspaceKey}>
              <SwapWorkspaceIcon
                style={{ color: theme.palette.secondary.main, fontSize: 18 }}
              />
              <div className={workspaceCurrentTextClassName}>
                {workspaceProducerName}
              </div>
            </div>
          );
        },
        renderWorkspaceOption: ({ workspaceKey, classes }) => {
          const workspaceProducerName =
            workspaceProducerNamesByWorkspaceKey[workspaceKey];

          return (
            <ListItem key={workspaceKey}>
              <ListItemAvatar>
                <Avatar>{workspaceProducerName.charAt(0)}</Avatar>
              </ListItemAvatar>
              <ListItemText
                classes={{ primary: classes.primary }}
                primary={workspaceProducerName}
              />
            </ListItem>
          );
        },
      };
    },
  }),
)((props) => <LoadingWrapper {...props} Component={AppWorkspaceMenu} />);
