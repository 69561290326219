import * as React from 'react';
import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import UserNotificationsQuery, {
  NotificationNode,
  UserNotificationsResponse,
} from '@graphql/queries/UserNotificationsQuery';
import { HighlightOffOutlined } from '@mui/icons-material';
import { IconButton, LinearProgress, Paper } from '@mui/material';
import RDTFirstPage from 'components/ReactDataTable/RDTFirstPage';
import RDTLastPage from 'components/ReactDataTable/RDTLastPage';
import RTDNextPage from 'components/ReactDataTable/RDTNextPage';
import RDTPrevPage from 'components/ReactDataTable/RDTPrevPage';
import TableSearch from 'components/Table/TableSearch';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import styles from './Notification.module.css';
import { NOTIFICATION_TYPE_ENUM } from '../../constants/enum';
import { PANEL, PRODUCT } from '../../constants/routePaths';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';
import { PageHeader, PageTitle } from '../../styles/themeComponents';
import formatPath from '../../utils/formatPath';

const DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss';

interface TableRow {
  id: number;
  item: string;
  message: any;
  submitter?: string;
  sentOn?: string;
  action?: any;
}

const Notification: React.FC = () => {
  const { t } = useTranslation();

  const workspaceId = useSelector((state) => selectWorkspaceProducerId(state));

  const dispatch = useDispatch();
  const handleReadNotification = (notificationId) =>
    dispatch({ type: 'UPDATE_NOTIFICATION', payload: notificationId });

  const [notifications, setNotifications] = useState<TableRow[]>([]);

  const { data, loading, error } = useQuery<UserNotificationsResponse>(
    UserNotificationsQuery,
    {
      variables: {
        producerId: workspaceId,
        active: true,
      },
    },
  );

  useEffect(() => {
    if (data && data.notifications && data.notifications.nodes) {
      setNotifications(getNotifications(data.notifications.nodes));
    }
  }, [data]);

  const getNotifications = (notificationList: NotificationNode[]): TableRow[] =>
    notificationList &&
    notificationList.map((notification) => ({
      id: notification.id,
      item:
        notification.notificationType.notificationType ===
        NOTIFICATION_TYPE_ENUM.QUICK_CREATE_PRODUCT
          ? notification.notificationType.product.name
          : notification.notificationType.notificationType ===
              NOTIFICATION_TYPE_ENUM.COMPLETED_PANEL
            ? notification.notificationType.panel.pin
            : null,
      message:
        notification.notificationType.notificationType ===
        NOTIFICATION_TYPE_ENUM.QUICK_CREATE_PRODUCT ? (
          <Link
            key={notification.notificationType.productId}
            to={{
              pathname: formatPath(PRODUCT, {
                productId: notification.notificationType.productId,
              }),
              state: notification.notificationType.productId,
            }}
          >
            {t('notification.messageQuickCreateProduct') +
              `${notification.notificationType.product.name}`}
          </Link>
        ) : notification.notificationType.notificationType ===
          NOTIFICATION_TYPE_ENUM.COMPLETED_PANEL ? (
          <Link
            key={notification.notificationType.panelId}
            to={{
              pathname: formatPath(PANEL, {
                panelId: notification.notificationType.panelId,
              }),
              state: notification.notificationType.panelId,
            }}
          >
            {t('notification.messageCompletedPanel') +
              `${notification.notificationType.panel.pin}`}
          </Link>
        ) : notification.notificationType.notificationType ===
            NOTIFICATION_TYPE_ENUM.MARKET_SURVEY_REPORT_READY ||
          notification.notificationType.notificationType ===
            NOTIFICATION_TYPE_ENUM.OPTIMIZATION_REPORT_READY ? (
          <div>
            {`${notification.notificationType.report.clientName} 
            ${notification.notificationType.report.projectName} 
            ${notification.notificationType.report.reportType} 
            report is ready`}
          </div>
        ) : (
          <div></div>
        ),
      submitter: notification.user.name,
      sentOn: formatDate(
        parseISO(notification.notificationType.sentOn),
        DATE_FORMAT,
      ),

      action:
        notification.notificationType.notificationType ===
          NOTIFICATION_TYPE_ENUM.QUICK_CREATE_PRODUCT &&
        notification.notificationType.product.updatedAt !==
          notification.notificationType.product.createdAt ? (
          <IconButton
            size="small"
            onClick={() => handleReadNotification(notification.id)}
          >
            <HighlightOffOutlined color="primary" fontSize="small" />
          </IconButton>
        ) : [
            NOTIFICATION_TYPE_ENUM.COMPLETED_PANEL,
            NOTIFICATION_TYPE_ENUM.MARKET_SURVEY_REPORT_READY,
            NOTIFICATION_TYPE_ENUM.OPTIMIZATION_REPORT_READY,
          ].includes(notification.notificationType.notificationType) ? (
          <IconButton
            size="small"
            onClick={() => handleReadNotification(notification.id)}
          >
            <HighlightOffOutlined color="primary" fontSize="small" />
          </IconButton>
        ) : (
          <div></div>
        ),
    }));

  const filterData = (term: string) => {
    if (!term.length) return;
    const filteredList =
      data &&
      data.notifications &&
      data.notifications.nodes.filter((row) =>
        JSON.stringify(row).toLowerCase().includes(term.toLowerCase()),
      );
    setNotifications(getNotifications(filteredList));
  };

  const columns: TableColumn<TableRow>[] = [
    {
      id: 'id',
      selector: (row: TableRow) => row.id,
      name: t('notification.notificationId'),
      sortable: true,
      omit: true,
    },
    {
      id: 'item',
      selector: (row: TableRow) => row.item,
      name: <div className={styles.tableHeader}>{t('notification.item')}</div>,
      sortable: true,
      wrap: true,
      omit: true,
    },
    {
      id: 'message',
      selector: (row: TableRow) => row.message,
      name: (
        <div className={styles.tableHeader}>{t('notification.message')}</div>
      ),
      sortable: true,
      wrap: true,
      width: '50%',
    },
    {
      id: 'submitter',
      selector: (row: TableRow) => row.submitter,
      name: (
        <div className={styles.tableHeader}>{t('notification.submitter')}</div>
      ),
      sortable: true,
      wrap: true,
      width: '20%',
    },
    {
      id: 'sentOn',
      selector: (row: TableRow) => row.sentOn,
      name: (
        <div className={styles.tableHeader}>{t('notification.sentOn')}</div>
      ),
      sortable: true,
      wrap: true,
      width: '20%',
    },
    {
      id: 'action',
      selector: (row: TableRow) => row.action,
      name: (
        <div className={styles.tableHeader}>{t('notification.remove')}</div>
      ),
      wrap: true,
      width: '10%',
    },
  ];

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <h3>Failed to load Notifications.</h3>;
  }

  return (
    <Paper className={styles.container}>
      <div>
        <div className={styles.actionContainer}>
          <div className={styles.headerTextContainer}>
            <PageHeader>{t('notification.notificationList')}</PageHeader>
            <PageTitle>{t('notification.viewNotification')}</PageTitle>
          </div>
          <TableSearch
            onSearch={(e) => filterData(e)}
            placeholder={t('general.search')}
          />
        </div>
        <DataTable
          columns={columns}
          data={notifications}
          fixedHeader
          striped
          pagination
          paginationIconFirstPage={<RDTFirstPage />}
          paginationIconLastPage={<RDTLastPage />}
          paginationIconPrevious={<RDTPrevPage />}
          paginationIconNext={<RTDNextPage />}
        />
      </div>
    </Paper>
  );
};

export default Notification;
