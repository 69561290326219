import { useState } from 'react';
import * as React from 'react';

import { FormControl, LinearProgress, MenuItem, Select } from '@mui/material';
import MaterialButton from 'components/MaterialButton';
import useSwapPanelProductReview from 'hooks/useSwapPanelProductReview';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';

const filterBoolean =
  <T,>(f: (x: T) => any) =>
  (x: T): x is T =>
    Boolean(f(x));

const SwapPanelProductReviews: React.FC = () => {
  const { t } = useTranslation();
  const producerId = useSelector((state) => selectWorkspaceProducerId(state));
  const {
    getProducerPanels,
    loadingProducerPanels,
    producerPanelsData,
    producerPanelsError,
    getPanelProducts,
    loadingPanelProducts,
    panelProductsData,
    panelProductsError,
    swapProductReviews,
    data,
    loading,
    error,
  } = useSwapPanelProductReview();
  const [panelId1, setPanelId1] = useState<number>(0);
  const [productId1, setProductId1] = useState<number>(0);
  const [panelId2, setPanelId2] = useState<number>(0);
  const [productId2, setProductId2] = useState<number>(0);
  const [products1, setProducts1] = useState<any[]>([]);
  const [products2, setProducts2] = useState<any[]>([]);

  React.useEffect(() => {
    getProducerPanels({
      variables: { producerId },
    });
  }, []);

  const onSelectPanel1 = async (panelId: number) => {
    setPanelId1(panelId);
    const prods = await getPanelProducts({ variables: { panelId } });
    prods &&
      prods.data &&
      prods.data.allPanelProducts &&
      setProducts1(prods.data.allPanelProducts.nodes);
  };

  const onSelectPanel2 = async (panelId: number) => {
    setPanelId2(panelId);
    const prods = await getPanelProducts({ variables: { panelId } });
    prods &&
      prods.data &&
      prods.data.allPanelProducts &&
      setProducts2(prods.data.allPanelProducts.nodes);
  };

  const onSwapReviews = async () => {
    await swapProductReviews(
      panelId1,
      productId1,
      panelId2,
      productId2,
    ).finally(() => {
      setPanelId1(0);
      setProductId1(0);
      setPanelId2(0);
      setProductId2(0);
      setProducts1([]);
      setProducts2([]);
    });
  };

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <h4>Can't load panels!</h4>;
  }

  return (
    <div style={{ width: '100%', paddingBottom: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '48%' }}>
          <FormControl variant="standard" fullWidth>
            {/* Panel1 Select */}
            <div style={{ marginBottom: '20px' }}>
              <label
                htmlFor="user-select"
                style={{
                  display: 'block',
                  marginBottom: '4px',
                  color: 'gray',
                }}
              >
                Panel 1
              </label>
              {producerPanelsData && producerPanelsData?.allPanels.nodes && (
                <Select
                  variant="standard"
                  style={{ width: '100%' }}
                  id="user-select"
                  fullWidth
                  onChange={(e) =>
                    onSelectPanel1(parseInt(e.target.value.toString()))
                  }
                  disabled={loading}
                >
                  {producerPanelsData?.allPanels.nodes
                    .filter(filterBoolean((item: any) => item.name))
                    .map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              )}
            </div>

            {/* Product1 Select */}
            {!!panelId1 && !!products1.length && (
              <div style={{ marginBottom: '20px' }}>
                <label
                  htmlFor="user-panel-select"
                  style={{
                    display: 'block',
                    marginBottom: '4px',
                    color: 'gray',
                  }}
                >
                  Product 1
                </label>
                <Select
                  variant="standard"
                  style={{ width: '100%' }}
                  id="user-panel-select"
                  fullWidth
                  onChange={(e) =>
                    setProductId1(parseInt(e.target.value.toString()))
                  }
                  disabled={loading}
                >
                  {products1.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.productByProductId.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}
          </FormControl>
        </div>
        <div style={{ width: '48%' }}>
          <FormControl variant="standard" fullWidth>
            {/* Panel2 Select */}
            <div style={{ marginBottom: '20px' }}>
              <label
                htmlFor="panel2-select"
                style={{
                  display: 'block',
                  marginBottom: '4px',
                  color: 'gray',
                }}
              >
                Panel 2
              </label>
              {producerPanelsData && producerPanelsData?.allPanels.nodes && (
                <Select
                  variant="standard"
                  style={{ width: '100%' }}
                  id="panel2-select"
                  fullWidth
                  onChange={(e) =>
                    onSelectPanel2(parseInt(e.target.value.toString()))
                  }
                  disabled={loading}
                >
                  {producerPanelsData?.allPanels.nodes
                    .filter(filterBoolean((item: any) => item.name))
                    .map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              )}
            </div>

            {/* Product2 Select */}
            {!!panelId2 && !!products2.length && (
              <div style={{ marginBottom: '20px' }}>
                <label
                  htmlFor="product2-select"
                  style={{
                    display: 'block',
                    marginBottom: '4px',
                    color: 'gray',
                  }}
                >
                  Product 2
                </label>
                <Select
                  variant="standard"
                  style={{ width: '100%' }}
                  id="product2-select"
                  fullWidth
                  onChange={(e) =>
                    setProductId2(parseInt(e.target.value.toString()))
                  }
                  disabled={loading}
                >
                  {products2.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.productByProductId.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}
          </FormControl>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '20px',
        }}
      >
        {!!panelId1 && !!productId1 && !!panelId2 && !!productId2 && (
          <MaterialButton
            variant="outlined"
            soft
            teal
            onClick={onSwapReviews}
            disabled={loading}
          >
            {(loading && 'Swapping review...') ||
              t('admin.swapPanelProductReviews')}
          </MaterialButton>
        )}
      </div>
    </div>
  );
};

export default SwapPanelProductReviews;
