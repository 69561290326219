import {
  adminIcon,
  dataExplore,
  languageIcon,
  profileIcon,
  signOutIcon,
} from 'constants/images';
import { INTERFACE_LANGUAGES } from 'constants/interfaceLanguages';

import * as React from 'react';
import { useEffect } from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { useTheme } from '@mui/material';
import { ViewerRoles } from 'containers/ConditionViewerRole/conditionViewerRole';
import i18next from 'i18next';
import { flowRight as compose } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { reset } from 'redux-form';
import { isAfsWorkspace } from 'utils/afsUtils';

import useStyles from './useStyles';
import AppViewerMenu from '../../components/AppViewerMenu';
import { NOTIFICATION_TYPE_ENUM } from '../../constants/enum';
import {
  REQUEST_REPORT_FORM,
  EDIT_PRODUCT_FORM,
  PANEL_FORM,
  PRODUCT_FORM,
  CREATE_USER_FORM,
  REPORT_QA_FORM,
  REPORT_REVISION_FORM,
  ADD_DEMOGRAPHIC_TARGET_FORM,
  EDIT_DEMOGRAPHIC_TARGET_FORM,
  CREATE_CATEGORY_FORM,
  PARTNER_BRANDING_FORM,
  ADD_CLIENT_FORM,
  GROUP_PRODUCER_FORM,
} from '../../constants/formNames';
import {
  ADMIN,
  SUPERADMIN,
  SUPERADMIN_DATA_EXPLORER,
  USER,
  LANGUAGES,
  PRODUCT,
  NOTIFICATION,
  PANEL,
} from '../../constants/routePaths';
import UserNotificationsQuery from '../../graphql/queries/UserNotificationsQuery';
import formatPath from '../../utils/formatPath';
import ConditionViewerRoleContainer from '../ConditionViewerRole';

interface Props {
  menuIsOpen: boolean;
  onClickCloseMenu: () => any;
  onClickOpenMenu: () => any;
  notificationIsOpen: boolean;
  glossaryIsOpen: boolean;
  onClickCloseNotification: () => any;
  onClickOpenNotification: () => any;
  onClickOpenGlossary: () => any;
  onClickCloseGlossary: () => any;
  onClickSignOut: () => any;
  viewerId?: number;
  workspaceProducerId?: number;
  viewerEmail?: string;
  viewerIsAuthenticated: boolean;
  viewerName?: string;
  viewerUsername?: string;
  viewerLanguage?: string;
  userNotifications?: any;
}

const AppViewerMenuContainer: React.FC<Props> = ({
  menuIsOpen,
  onClickCloseMenu,
  onClickOpenMenu,
  notificationIsOpen,
  onClickCloseNotification,
  onClickOpenNotification,
  glossaryIsOpen,
  onClickCloseGlossary,
  onClickOpenGlossary,
  onClickSignOut,
  viewerId,
  workspaceProducerId,
  viewerEmail,
  viewerName,
  viewerIsAuthenticated,
  viewerUsername,
  viewerLanguage,
  userNotifications,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles(useTheme());

  const numOfNotifications = 5;

  useEffect(() => {
    (async function setLanguage(language) {
      if (INTERFACE_LANGUAGES.find((il) => il.unicode === language)) {
        await i18next.changeLanguage(language);
      } else {
        await i18next.changeLanguage('en');
      }
    })(viewerLanguage);
  }, [viewerLanguage]);

  const showAdminTools = (
    viewerRoles: ViewerRoles,
    workspaceProducerId: number,
  ): boolean => {
    if (
      viewerRoles.viewerRoleIsPartnerAdmin &&
      !viewerRoles.viewerRoleIsSuperadmin
    )
      return true;
    if (
      (viewerRoles.viewerRoleIsAdmin || viewerRoles.viewerRoleIsPartnerAdmin) &&
      !viewerRoles.viewerRoleIsSuperadmin &&
      isAfsWorkspace(workspaceProducerId)
    )
      return true;
    return false;
  };

  const showSuperAdminTools = (viewerRoles: ViewerRoles): boolean => {
    if (viewerRoles.viewerRoleIsSuperadmin) return true;
    return false;
  };

  const signOut = () => {
    dispatch(reset(REQUEST_REPORT_FORM));
    dispatch(reset(EDIT_PRODUCT_FORM));
    dispatch(reset(PANEL_FORM));
    dispatch(reset(PRODUCT_FORM));
    dispatch(reset(CREATE_USER_FORM));
    dispatch(reset(REPORT_QA_FORM));
    dispatch(reset(REPORT_REVISION_FORM));
    dispatch(reset(ADD_DEMOGRAPHIC_TARGET_FORM));
    dispatch(reset(EDIT_DEMOGRAPHIC_TARGET_FORM));
    dispatch(reset(CREATE_CATEGORY_FORM));
    dispatch(reset(PARTNER_BRANDING_FORM));
    dispatch(reset(ADD_CLIENT_FORM));
    dispatch(reset(GROUP_PRODUCER_FORM));
    onClickSignOut();
  };

  return (
    <AppViewerMenu
      menuIsOpen={menuIsOpen}
      onClickCloseMenu={onClickCloseMenu}
      onClickOpenMenu={onClickOpenMenu}
      notificationIsOpen={notificationIsOpen}
      onClickCloseNotification={onClickCloseNotification}
      onClickOpenNotification={onClickOpenNotification}
      glossaryIsOpen={glossaryIsOpen}
      onClickCloseGlossary={onClickCloseGlossary}
      onClickOpenGlossary={onClickOpenGlossary}
      renderMenuContents={({ menuSectionClassName }) => [
        <div className={menuSectionClassName} key={menuSectionClassName}>
          <div className={classes.menuContainer}>
            <div className={classes.accessory} />
            <div className={classes.textContainer}>
              <img src={profileIcon} alt="profile-icon" />
              <Link
                className={classes.menuItem}
                to={formatPath(USER, { userId: viewerId })}
                onClick={onClickCloseMenu}
              >
                {t('viewerMenu.profile')}
              </Link>
            </div>
          </div>

          <div className={classes.menuContainer}>
            <div className={classes.accessory} />
            <div className={classes.textContainer}>
              <img src={languageIcon} alt="language-icon" />
              <Link
                className={classes.menuItem}
                to={LANGUAGES}
                onClick={onClickCloseMenu}
              >
                {t('viewerMenu.changeLanguage')}
              </Link>
            </div>
          </div>

          <ConditionViewerRoleContainer
            render={(viewerRoles) =>
              showSuperAdminTools(viewerRoles) && (
                <div className={classes.menuContainer}>
                  <div className={classes.accessory} />
                  <div className={classes.textContainer}>
                    <img src={dataExplore} alt="data-explore-icon" />
                    <Link
                      className={classes.menuItem}
                      to={SUPERADMIN_DATA_EXPLORER}
                      onClick={onClickCloseMenu}
                    >
                      {t('viewerMenu.adminDataExplorer')}
                    </Link>
                  </div>
                </div>
              )
            }
          />

          <ConditionViewerRoleContainer
            render={(viewerRoles) =>
              showAdminTools(viewerRoles, workspaceProducerId) && (
                <div className={classes.menuContainer}>
                  <div className={classes.accessory} />
                  <div className={classes.textContainer}>
                    <img src={adminIcon} alt="admin-icon" />
                    <Link
                      className={classes.menuItem}
                      to={ADMIN}
                      onClick={onClickCloseMenu}
                    >
                      {t('viewerMenu.adminTools')}
                    </Link>
                  </div>
                </div>
              )
            }
          />

          <ConditionViewerRoleContainer
            render={(viewerRoles) =>
              showSuperAdminTools(viewerRoles) && (
                <div className={classes.menuContainer}>
                  <div className={classes.accessory} />
                  <div className={classes.textContainer}>
                    <img src={adminIcon} alt="admin-icon" />
                    <Link
                      className={classes.menuItem}
                      to={SUPERADMIN}
                      onClick={onClickCloseMenu}
                    >
                      {t('viewerMenu.superadminTools')}
                    </Link>
                  </div>
                </div>
              )
            }
          />

          <div className={classes.menuContainer}>
            <div className={classes.accessory} />
            <div
              className={classes.textContainer}
              style={{ borderBottom: 'none' }}
            >
              <img src={signOutIcon} alt="sign-out-icon" />
              <a className={classes.menuItem} onClick={signOut} tabIndex={-1}>
                {t('viewerMenu.signOut')}
              </a>
            </div>
          </div>
        </div>,
      ]}
      renderNotificationContents={({ notificationSectionClassName }) =>
        userNotifications &&
        userNotifications.notifications &&
        userNotifications.notifications.nodes ? (
          <div
            className={notificationSectionClassName}
            key={notificationSectionClassName}
          >
            {userNotifications.notifications.nodes
              .slice(-numOfNotifications)
              .reverse()
              .map((notification) => (
                <div
                  key={`notification-${notification.id}`}
                  className={classes.menuContainer}
                >
                  <div className={classes.accessory} />
                  <div className={classes.notificationContainer}>
                    {notification.notificationType.notificationType ===
                    NOTIFICATION_TYPE_ENUM.QUICK_CREATE_PRODUCT ? (
                      <Link
                        className={classes.menuItem}
                        key={notification.notificationType.productId}
                        to={{
                          pathname: formatPath(PRODUCT, {
                            productId: notification.notificationType.productId,
                          }),
                          state: notification.notificationType.productId,
                        }}
                      >
                        {t('notification.messageQuickCreateProduct') +
                          `${notification.notificationType.product.name}`}
                      </Link>
                    ) : notification.notificationType.notificationType ===
                      NOTIFICATION_TYPE_ENUM.COMPLETED_PANEL ? (
                      <Link
                        className={classes.menuItem}
                        key={notification.notificationType.panelId}
                        to={{
                          pathname: formatPath(PANEL, {
                            panelId: notification.notificationType.panelId,
                          }),
                          state: notification.notificationType.productId,
                        }}
                      >
                        {t('notification.messageCompletedPanel') +
                          `${notification.notificationType.panel.pin}`}
                      </Link>
                    ) : notification.notificationType.notificationType ===
                        NOTIFICATION_TYPE_ENUM.MARKET_SURVEY_REPORT_READY ||
                      notification.notificationType.notificationType ===
                        NOTIFICATION_TYPE_ENUM.OPTIMIZATION_REPORT_READY ? (
                      <Link
                        className={classes.menuItem}
                        key={notification.notificationType.reportId}
                        to={{
                          pathname: NOTIFICATION,
                        }}
                      >
                        {`${notification.notificationType.report.clientName} 
                          ${notification.notificationType.report.projectName} 
                          ${notification.notificationType.report.reportType} 
                          report is ready`}
                      </Link>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              ))}
            <div className={classes.menuContainer}>
              <div className={classes.accessory} />
              <div className={classes.allNotification}>
                <Link to={NOTIFICATION} className={classes.menuItem}>
                  {t('notification.seeAllNotifications')}
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.menuContainer}>
            <div className={classes.accessory} />
            <div className={classes.noNotification}>
              {t('notification.noNotification')}
            </div>
          </div>
        )
      }
      viewerId={viewerId}
      viewerEmail={viewerEmail}
      viewerName={viewerName}
      viewerIsAuthenticated={viewerIsAuthenticated}
      workspaceProducerId={workspaceProducerId}
    />
  );
};

AppViewerMenuContainer.displayName = 'AppViewerMenuContainer';

export default compose(
  graphql(UserNotificationsQuery, {
    options: ({ workspaceProducerId }: Props) => ({
      variables: {
        producerId: workspaceProducerId ?? 0,
        active: true,
      },
    }),
    name: 'userNotifications',
  }),
)(AppViewerMenuContainer);
