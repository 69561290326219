import * as Sentry from '@sentry/react';
import submitConchBatch from 'consumers/httpSubmitBatch';
import { compressParams } from 'containers/RequestReport/utils';
import {
  change,
  getFormValues,
  reset,
  startSubmit,
  stopSubmit,
} from 'redux-form';
import { put, select, take, call } from 'redux-saga/effects';

import errorAction from '../actions/error';
import { FORM_SUBMIT } from '../actions/formSubmit';
import { REQUEST_REPORT_FORM } from '../constants/formNames';
import graphqlClient from '../consumers/graphqlClient';
import ViewerQuery from '../graphql/queries/ViewerQuery';
import selectTokenRoles from '../selectors/sessionTokenRoles';
import selectWorkspaceProducerId from '../selectors/workspaceProducerId';
import {
  exceededRequestReportQuota,
  getJobQueue,
  makeReportParams,
} from '../utils/conchUtils';
import { hasSuperAdminRole } from '../utils/roleUtils';

export default function* requestReportFormSubmitSaga() {
  while (true) {
    yield take(
      ({ type, payload }) =>
        type === FORM_SUBMIT && payload === REQUEST_REPORT_FORM,
    );

    yield put(startSubmit(REQUEST_REPORT_FORM));
    try {
      const viewerInfoResponse = yield graphqlClient.query({
        query: ViewerQuery,
      });
      const viewerInfoResult = viewerInfoResponse.data;
      const viewerInfoData = viewerInfoResult.viewer;

      const formValues = yield select(getFormValues(REQUEST_REPORT_FORM));

      const workspaceProducerId = yield select(selectWorkspaceProducerId);
      const userRoles = yield select(selectTokenRoles);

      if (!hasSuperAdminRole(userRoles)) {
        const hasExceededQuota = yield call(
          exceededRequestReportQuota,
          viewerInfoData.id,
          workspaceProducerId,
        );
        if (hasExceededQuota) {
          yield put(change(REQUEST_REPORT_FORM, 'submitFailed', true));
          yield put(change(REQUEST_REPORT_FORM, 'limitExceeded', true));
          yield put(stopSubmit(REQUEST_REPORT_FORM));
          return;
        }
      }

      const report_params:
        | conch.MarketSurveyReportParams
        | conch.OptimizationReportParams
        | conch.UpdateReportParams = makeReportParams(
        formValues,
        viewerInfoData,
      );

      const jobName = [
        report_params.client_name,
        report_params.project_name,
        report_params.target_group_name,
      ]
        .map((d) => d.replace(/ /g, '-'))
        .join('_');

      const report_params_zipped =
        report_params.report_type == 'market_survey' &&
        (report_params as conch.MarketSurveyReportParams)
          .custom_class_db_products.length > 0
          ? compressParams(JSON.stringify(report_params))
          : null;

      // Submit request to conch batch service
      yield submitConchBatch(
        jobName,
        report_params.job_definition,
        getJobQueue(report_params.numCores),
        report_params,
        report_params_zipped,
      );

      // Add request to DB

      // End saga
      yield put(stopSubmit(REQUEST_REPORT_FORM));
      yield put(reset(REQUEST_REPORT_FORM));
      yield put(
        change(REQUEST_REPORT_FORM, 'submittedType', formValues.report_type),
      );
      yield put(change(REQUEST_REPORT_FORM, 'submitSucceeded', true));
      yield put(
        change(
          REQUEST_REPORT_FORM,
          'submittedProjectName',
          report_params.project_name,
        ),
      );
    } catch (error) {
      yield Sentry.captureException(error);
      yield put(stopSubmit(REQUEST_REPORT_FORM, error));
      yield put(change(REQUEST_REPORT_FORM, 'submitFailed', true));
      yield put(
        errorAction({
          error,
          title: 'Failed to Request Report',
          description: error.message,
        }),
      );
    }
  }
}
