import { gql } from '@apollo/client';

export default gql`
  query textureClusterNames($reportID: UUID) {
    allRpTextureClusterNames(condition: { reportId: $reportID }) {
      nodes {
        clusterIdx
        clusterName
      }
    }
  }
`;
