import { getCountryWithEmoji } from 'constants/country';
import { REQUEST_REPORT_OPEN } from 'constants/googleAnalytics/actions';
import { CAT_REPORT_LIST } from 'constants/googleAnalytics/categories';

import * as React from 'react';
import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import UpdateReportProjectName from '@graphql/mutations/UpdateReportProjectName';
import UpdateReportWorkspace from '@graphql/mutations/UpdateReportWorkspace';
import CreateOutlined from '@mui/icons-material/CreateOutlined';
import { CircularProgress, IconButton, Paper } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import MaterialButton from 'components/MaterialButton';
import RDTFirstPage from 'components/ReactDataTable/RDTFirstPage';
import RDTLastPage from 'components/ReactDataTable/RDTLastPage';
import RTDNextPage from 'components/ReactDataTable/RDTNextPage';
import RDTPrevPage from 'components/ReactDataTable/RDTPrevPage';
import { getReportParam } from 'components/Report/ReportDemographics/utils';
import ReportDownloadIcon from 'components/Report/ReportDownloadIcon';
import { isArchtypeReportId } from 'components/Report/utils';
import ReportViewerMenu from 'components/ReportViewerMenu';
import TableSearch from 'components/Table/TableSearch';
import httpGetPartnerAdmins from 'consumers/httpGetPartnerAdmins';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import useAllReports from 'hooks/useAllReports';
import useHideOlark from 'hooks/useHideOlark';
import useWindowSize from 'hooks/useWindowSize';
import { findIndex, groupBy, sortBy } from 'lodash';
import DataTable, { TableColumn } from 'react-data-table-component';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Alert from 'react-s-alert';
import { Optional } from 'typescript-optional';
import { getDebouncedEventFn } from 'utils/googleAnalyticsHelper';
import { isSuccessful } from 'utils/reportUtils';

import ApprovalModal from './ApprovalModal';
import styles from './ReportsQATable.module.css';
import LoadingScreen from '../../components/LoadingScreen';
import { NOTIFICATION_TYPE_ENUM, ROLE_ENUM } from '../../constants/enum';
import { OPTIMIZATION, formatReportType } from '../../constants/report';
import { REQUEST_REPORT } from '../../constants/routePaths';
import graphqlClient from '../../consumers/graphqlClient';
import CreateNotificationMutation from '../../graphql/mutations/CreateNotification';
import CreateNotificationTypeMutation from '../../graphql/mutations/CreateNotificationType';
import UpdateReportJob from '../../graphql/mutations/UpdateReportJob';
import AllWorkspacesQuery from '../../graphql/queries/AllWorkspacesQuery';
import sessionToken from '../../selectors/sessionToken';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';
import { PageHeader } from '../../styles/themeComponents';

const { event } = ReactGA;

const DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss';

const debouncedEvent = getDebouncedEventFn(1000);

interface WorkspaceOption {
  id: number;
  name: string;
  value: number;
}

interface TableRow {
  id: number;
  reportId: string;
  partnerName: string;
  clientName: string;
  name: string;
  reportTypeDisplay: string;
  demographic: string;
  user: string;
  date: string;
  dashboard: string;
  status: string;
  file: string;
  action: string;
}

const ReportsQATable: React.FC = () => {
  const { t } = useTranslation();
  const [appViewerState, setAppViewerState] = useState<boolean>(false);
  const [currentReportId, setCurrentReportId] = useState<number>();

  const producerId = useSelector((state) => selectWorkspaceProducerId(state));

  const { widthBelow700, widthBelow1000, widthBelow1100, widthBelow1400 } =
    useWindowSize();

  const [currentSearchTerm, setCurrentSearchTeam] = useState<string>('');
  const [currentData, setCurrentData] = useState<reports.AllReportsQueryRow[]>(
    [],
  );
  const [openWorkspaceEditModal, setWorkspaceEditModal] =
    useState<boolean>(false);
  const [openProjectEditModal, setProjectEditModal] = useState<boolean>(false);
  const [showApprovalModal, setShowApprovalModal] = useState<boolean>(false);
  const [showMapOnlyApprovalModal, setShowMapOnlyApprovalModal] =
    useState<boolean>(false);
  const [reportId, setReportId] = useState<string>(null);
  const [reportRowId, setReportRowId] = useState<number>(null);
  const [workspaceEditReport, setWorkspaceEditReport] = useState(null);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<number>(-1);
  const [hoveredWorkspaceReport, setHoveredWorkspaceReport] =
    useState<string>(null);
  const [projectEditReport, setProjectEditReport] =
    useState<reports.AllReportsQueryRow>(null);
  const [newProjectName, setNewProjectName] = useState<string>('');
  const [hoveredProjectReport, setHoveredProjectReport] = useState(null);
  const authToken = useSelector((state) => sessionToken(state));

  const { loading, error, data: reportJobsResults } = useAllReports();

  useHideOlark();

  const {
    loading: workspacesLoading,
    error: workspacesError,
    data: workspaces,
  } = useQuery(AllWorkspacesQuery);

  const AlertType = {
    success: 'success',
    error: 'error',
  };

  const PageAlert = (type, message) => {
    return Alert[AlertType[type]](message, {
      position: 'top-right',
      effect: 'slide',
      beep: false,
      timeout: 4000,
    });
  };

  const formatAndSaveDataToState = (data: reports.AllReportsQueryRow[]) => {
    setCurrentData(data);
  };

  const formatWorkspaceOptions = (workspaces): WorkspaceOption[] => {
    return workspaces.allProducers.nodes
      .map((n) => ({
        id: n.id,
        name: n.name,
        value: n.id,
      }))
      .sort((a: WorkspaceOption, b: WorkspaceOption) =>
        a.name.localeCompare(b.name),
      );
  };

  useEffect(() => {
    formatAndSaveDataToState(reportJobsResults);
  }, [reportJobsResults]);

  const updateObjectInState = (
    id: number,
    status: boolean,
    mapOnly: boolean,
    currentData: any[],
  ) => {
    const data = currentData;
    const dataIndex = findIndex(data, { id });
    const dataObject = data[dataIndex];
    const updateList = [...data];
    updateList[dataIndex] = {
      ...dataObject,
      passedQa: status,
      mapOnly: mapOnly,
    };
    setCurrentData(updateList);
  };

  const createNotification = async (
    userId: number,
    reportId: string,
    reportType: string,
    producerId: number,
  ) => {
    const notificationType =
      reportType === OPTIMIZATION
        ? NOTIFICATION_TYPE_ENUM.OPTIMIZATION_REPORT_READY
        : NOTIFICATION_TYPE_ENUM.MARKET_SURVEY_REPORT_READY;
    const {
      data: {
        createNotificationType: {
          notificationType: { id: notificationTypeId },
        },
      },
    } = await graphqlClient.mutate({
      mutation: CreateNotificationTypeMutation,
      variables: {
        notificationType: {
          role: ROLE_ENUM.GASTROGRAPH_USER,
          notificationType: notificationType,
          reportId: reportId,
        },
      },
    });

    await graphqlClient.mutate({
      mutation: CreateNotificationMutation,
      variables: {
        notification: {
          userId: userId,
          producerId: producerId,
          active: true,
          notificationTypeId: notificationTypeId,
        },
      },
    });
  };

  const updateReportJobStatus = async (
    id: number,
    status: boolean,
    mapOnly: boolean,
    currentData: any[],
  ) => {
    const job = currentData[findIndex(currentData, { id })];
    await graphqlClient
      .mutate({
        mutation: UpdateReportJob,
        variables: {
          id,
          reportJobPatch: { passedQa: status, mapOnly: mapOnly },
        },
      })
      .then(async (res) => {
        updateObjectInState(id, status, mapOnly, currentData);
        if (status && job.producerByWorkspaceId.producerPartnerByProducerId) {
          const partner =
            job.producerByWorkspaceId.producerPartnerByProducerId
              .partnerByPartnerId;
          const partnerAdminsResponse = await httpGetPartnerAdmins(
            partner.id,
            authToken,
          );
          if (!partnerAdminsResponse) {
            console.error('Partner admins could not be found');
          }
          partnerAdminsResponse.forEach(async (user) => {
            const notification_res = await createNotification(
              user.user_id,
              job.reportId,
              job.reportType,
              job.workspaceId,
            );
            console.log(notification_res);
          });
        }
        return PageAlert(AlertType.success, 'Changes saved successfully');
      })
      .catch((error) => {
        console.error(error);
        PageAlert(AlertType.error, 'Changes not saved. Please try again');
      });
  };

  const getFormattedProductData = (localData) => {
    const reportJobsReports = Object.entries(
      groupBy(localData, (i) => i.rootId || i.id),
    )
      .map((res) => sortBy(res[1], [(i) => (isNaN(i) ? 0 : -i)]))
      .map((res: reports.AllReportsQueryRow[]) => ({
        key: `ms_${res[0].id}`,
        id: res[0].id,
        reportId: res[0].reportId,
        reportType: res[0].reportType,
        reportTypeDisplay: formatReportType(res[0].reportType),
        user: res[0].requestedThrough,
        name: (
          <div
            onMouseEnter={() => setHoveredProjectReport(res[0].reportId)}
            onMouseLeave={() => setHoveredProjectReport(null)}
          >
            {res[0].projectName} &nbsp;
            {hoveredProjectReport == res[0].reportId && !res[0].passedQa && (
              <IconButton
                size="small"
                onClick={() => {
                  setProjectEditReport(res[0]);
                  setNewProjectName(res[0].projectName);
                  setProjectEditModal(true);
                }}
              >
                <CreateOutlined color="primary" fontSize="small" />
              </IconButton>
            )}
          </div>
        ),
        demographic: getCountryWithEmoji(
          getReportParam<string | string[]>(
            Optional.of(res[0].params),
            'countries',
          ),
        ),
        date: formatDate(parseISO(res[0].startedAt), DATE_FORMAT),
        status: res[0].reportStatus,
        clientName: (
          <div
            onMouseEnter={() => setHoveredWorkspaceReport(res[0].reportId)}
            onMouseLeave={() => setHoveredWorkspaceReport(null)}
          >
            {res[0].producerByWorkspaceId.name} &nbsp;
            {hoveredWorkspaceReport == res[0].reportId && !res[0].passedQa && (
              <IconButton
                size="small"
                onClick={() => {
                  setWorkspaceEditReport(res[0]);
                  setSelectedWorkspaceId(res[0].workspaceId);
                  setWorkspaceEditModal(true);
                }}
              >
                <CreateOutlined color="primary" fontSize="small" />
              </IconButton>
            )}
          </div>
        ),
        partnerName:
          res[0].producerByWorkspaceId?.producerPartnerByProducerId
            ?.partnerByPartnerId?.name,
        file:
          isSuccessful(res[0]) && !isArchtypeReportId(res[0].reportId) ? (
            <ReportDownloadIcon reportId={res[0].reportId} pdfIcon />
          ) : null,
        action: (
          <ReportViewerMenu
            updateReportJobStatus={updateReportJobStatus}
            res={res}
            localData={localData}
            setReportRowId={setReportRowId}
            setReportId={setReportId}
            setShowApprovalModal={setShowApprovalModal}
            setShowMapOnlyApprovalModal={setShowMapOnlyApprovalModal}
          />
        ),
      }));
    return reportJobsReports;
  };

  const columns: TableColumn<TableRow>[] = [
    {
      id: 'id',
      selector: (row: TableRow) => row.id,
      name: 'ID',
      sortable: true,
      omit: true,
    },
    {
      id: 'reportId',
      selector: (row: TableRow) => row.reportId,
      name: 'Report ID',
      sortable: true,
      omit: true,
    },
    {
      id: 'partnerName',
      selector: (row: TableRow) => row.partnerName,
      name: <div className={styles.tableHeader}>{t('workspace.partner')}</div>,
      sortable: true,
      wrap: true,
      omit: widthBelow1000,
    },
    {
      id: 'workspace',
      selector: (row: TableRow) => row.clientName,
      name: <div className={styles.tableHeader}>{t('reports.workspace')}</div>,
      sortable: true,
      wrap: true,
      omit: widthBelow1000,
    },
    {
      id: 'name',
      selector: (row: TableRow) => row.name,
      name: <div className={styles.tableHeader}>{t('panel.projectName')}</div>,
      sortable: true,
      wrap: true,
    },
    {
      id: 'reportTypeDisplay',
      selector: (row: TableRow) => row.reportTypeDisplay,
      name: <div className={styles.tableHeader}>{t('reports.reportType')}</div>,
      sortable: true,
      wrap: true,
      width: '125px',
      omit: widthBelow1000,
    },
    {
      id: 'demographic',
      selector: (row: TableRow) => row.demographic,
      name: (
        <div className={styles.tableHeader}>{t('reports.targetGroup')}</div>
      ),
      sortable: true,
      wrap: true,
      omit: widthBelow1000,
    },
    {
      id: 'user',
      selector: (row: TableRow) => row.user,
      name: <div className={styles.tableHeader}>{t('reports.submitter')}</div>,
      sortable: true,
      wrap: true,
      omit: widthBelow1100,
    },
    {
      id: 'submittedOn',
      selector: (row: TableRow) => row.date,
      name: (
        <div className={styles.tableHeader}>{t('reports.submittedOn')}</div>
      ),
      sortable: true,
      wrap: true,
      omit: widthBelow1400,
    },
    {
      id: 'dashboard',
      selector: (row: TableRow) => row.dashboard,
      name: <div className={styles.tableHeader}>{t('reports.dashboard')}</div>,
      sortable: true,
      wrap: true,
      omit: true,
    },
    {
      id: 'status',
      selector: (row: TableRow) => row.status,
      name: <div className={styles.tableHeader}>{t('reports.status')}</div>,
      sortable: true,
      wrap: true,
      width: '100px',
      omit: widthBelow700,
    },
    {
      id: 'pdf',
      selector: (row: TableRow) => row.file,
      name: <div className={styles.tableHeader}>{t('general.pdf')}</div>,
      sortable: false,
      wrap: true,
      width: '65px',
      omit: false,
    },
    {
      id: 'action',
      selector: (row: TableRow) => row.action,
      name: <div className={styles.tableHeader}>{t('general.action')}</div>,
      sortable: false,
      wrap: true,
      width: '140px',
    },
  ];

  if (!producerId) {
    return <div></div>;
  }

  if (reportJobsResults.length == 0 || workspacesLoading) {
    return <LoadingScreen />;
  }

  if (error || workspacesError) {
    return <h3>Failed to load reports data.</h3>;
  }

  const getJobs = (
    currentData: reports.AllReportsQueryRow[],
    currentSearchTerm: string,
  ): any[] => {
    if (!currentSearchTerm) {
      return getFormattedProductData(currentData);
    } else {
      return getFormattedProductData(
        currentData.filter((row) =>
          JSON.stringify(row)
            .toLowerCase()
            .includes(currentSearchTerm.toLowerCase()),
        ),
      );
    }
  };

  return (
    <div>
      {showApprovalModal && (
        <ApprovalModal
          reportId={reportId}
          displayOnly={false}
          cancelButtonText={t('general.cancel')}
          cancelFunction={() => {
            setShowApprovalModal(false);
            setReportId(null);
          }}
          confirmButtonText={t('reports.qaApproved')}
          confirmFunction={() => {
            setShowApprovalModal(false);
            setReportId(null);
            updateReportJobStatus(reportRowId, true, false, currentData);
          }}
        />
      )}
      {showMapOnlyApprovalModal && (
        <ApprovalModal
          reportId={reportId}
          displayOnly={false}
          cancelButtonText={t('general.cancel')}
          cancelFunction={() => {
            setShowMapOnlyApprovalModal(false);
            setReportId(null);
          }}
          confirmButtonText={t('reports.qaMapOnlyApproved')}
          confirmFunction={() => {
            setShowMapOnlyApprovalModal(false);
            setReportId(null);
            updateReportJobStatus(reportRowId, true, true, currentData);
          }}
        />
      )}
      <Modal
        className={styles.modal}
        open={openWorkspaceEditModal}
        onClose={() => setWorkspaceEditModal(false)}
      >
        <div className={styles.modalContainer}>
          <h3>Edit Report Workspace</h3>
          <p>Report ID: {workspaceEditReport?.reportId}</p>
          <Autocomplete
            id="select-report-update-workspace"
            options={formatWorkspaceOptions(workspaces)}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select workspace"
                variant="outlined"
              />
            )}
            onChange={(event, workspace) =>
              setSelectedWorkspaceId((workspace as WorkspaceOption).id)
            }
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px',
              marginTop: '10px',
            }}
          >
            <MaterialButton
              variant="outlined"
              soft
              onClick={() => setWorkspaceEditModal(false)}
            >
              {'Cancel'}
            </MaterialButton>
            <MaterialButton
              variant="outlined"
              soft
              teal
              onClick={() => {
                graphqlClient
                  .mutate({
                    mutation: UpdateReportWorkspace,
                    variables: {
                      jobId: workspaceEditReport.jobId,
                      workspaceId: selectedWorkspaceId,
                    },
                  })
                  .then((update) => {
                    const updatedWorkspace =
                      update.data?.updateReportJobByJobId
                        ?.producerByWorkspaceId;
                    const updatedWorkspaceId = updatedWorkspace.id;
                    const updateList = [...reportJobsResults];
                    const i = findIndex(updateList, {
                      reportId: workspaceEditReport.reportId,
                    });
                    updateList[i] = {
                      ...updateList[i],
                      producerByWorkspaceId: updatedWorkspace,
                      workspaceId: updatedWorkspaceId,
                    };
                    formatAndSaveDataToState(updateList);
                    setWorkspaceEditModal(false);
                  });
              }}
            >
              {'Save'}
            </MaterialButton>
          </div>
        </div>
      </Modal>
      <Modal
        className={styles.modal}
        open={openProjectEditModal}
        onClose={() => setProjectEditModal(false)}
      >
        <div className={styles.modalContainer}>
          <h3>Edit Report Project Name</h3>
          <p>Report ID: {projectEditReport?.reportId}</p>
          <input
            id="enter-report-new-project-name"
            onChange={(event) => setNewProjectName(event.target.value)}
            placeholder="Enter new project name"
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px',
              marginTop: '10px',
            }}
          >
            <MaterialButton
              variant="outlined"
              soft
              onClick={() => setProjectEditModal(false)}
            >
              {'Cancel'}
            </MaterialButton>
            <MaterialButton
              variant="outlined"
              soft
              teal
              onClick={() => {
                graphqlClient
                  .mutate({
                    mutation: UpdateReportProjectName,
                    variables: {
                      jobId: projectEditReport.jobId,
                      projectName: newProjectName,
                    },
                  })
                  .then((update) => {
                    const updatedProjectName =
                      update.data?.updateReportJobByJobId?.reportJob
                        .projectName;
                    const updateList = [...reportJobsResults];
                    const i = findIndex(updateList, {
                      reportId: projectEditReport.reportId,
                    });
                    updateList[i] = {
                      ...updateList[i],
                      projectName: updatedProjectName,
                    };
                    formatAndSaveDataToState(updateList);
                    setProjectEditModal(false);
                  });
              }}
            >
              {'Save'}
            </MaterialButton>
          </div>
        </div>
      </Modal>

      <Paper className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.headerTextContainer}>
            <PageHeader>
              {`${t('navigation.reportsQa')} - ${reportJobsResults.length}`}
              {loading && <CircularProgress size="0.5rem" color="inherit" />}
            </PageHeader>
            <h3 className={styles.productTitle}>{t('reports.requests')}</h3>
          </div>
          <TableSearch
            onSearch={(e) => setCurrentSearchTeam(e)}
            placeholder={t('general.search')}
          />

          <Link
            to={REQUEST_REPORT}
            onClick={() =>
              event({
                category: CAT_REPORT_LIST,
                action: REQUEST_REPORT_OPEN,
              })
            }
          >
            <MaterialButton variant="outlined" soft teal>
              {t('reports.createReportRequest')}
            </MaterialButton>
          </Link>
        </div>
        <div>
          <DataTable
            columns={columns}
            data={getJobs(currentData, currentSearchTerm)}
            defaultSortFieldId="submittedOn"
            defaultSortAsc={false}
            fixedHeader
            striped
            pagination
            paginationIconFirstPage={<RDTFirstPage />}
            paginationIconLastPage={<RDTLastPage />}
            paginationIconPrevious={<RDTPrevPage />}
            paginationIconNext={<RTDNextPage />}
          />
        </div>
      </Paper>
    </div>
  );
};

export default ReportsQATable;
