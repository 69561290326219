import {
  deviceSvg,
  panelSvg,
  productSvg,
  reportSvg,
  reviewSvg,
  userSvg,
} from 'constants/images';

import * as React from 'react';
import { useState } from 'react';

import * as MaterialIcons from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';
import useGgEnvironment from 'hooks/useGgEnvironment';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { partnerPortal } from 'services/partnerPortal';
import { isAfsWorkspace } from 'utils/afsUtils';
import { isProd } from 'utils/environmentUtils';

import useStyles from './useStyles';
import {
  PANELS,
  PRODUCTS,
  USERS,
  SUPERADMIN_MANAGE_DEVICES,
  REPORTS_QA,
  REPORT_DASHBOARD,
  REQUEST_REPORT,
  CUSTOM_LEXICON,
  PROJECTS,
  PQ_MODEL_PLAYGROUND,
} from '../../constants/routePaths';
import ProductFolderComponent from '../../containers/ProductFolder';
import formatPath from '../../utils/formatPath';

interface Props {
  className?: string;
  workspaceProducerId?: number;
  viewerRoleIsAdmin: boolean;
  viewerRoleIsSuperadmin: boolean;
  viewerRoleIsWorkspaceAdmin: boolean;
  viewerRoleIsPanelManage: boolean;
  viewerRoleIsTextureAnalysis: boolean;
  viewerRoleIsAnalysisView: boolean;
  viewerRoleIsProductReview: boolean;
  viewerRoleIsPartnerAdmin: boolean;
}

const renderItems = (classes, items, active, setActive) =>
  items.map((item) => {
    const Icon = () => {
      switch (item.iconName) {
        case 'productSvg':
          return (
            <img className={classes.icon} src={productSvg} alt="product-icon" />
          );

        case 'panelSvg':
          return (
            <img className={classes.icon} src={panelSvg} alt="panel-icon" />
          );

        case 'userSvg':
          return <img className={classes.icon} src={userSvg} alt="user-icon" />;

        case 'reportSvg':
          return (
            <img className={classes.icon} src={reportSvg} alt="report-icon" />
          );

        case 'reviewSvg':
          return (
            <img className={classes.icon} src={reviewSvg} alt="review-icon" />
          );

        case 'deviceSvg':
          return (
            <img className={classes.icon} src={deviceSvg} alt="device-icon" />
          );

        default: {
          const Icon = MaterialIcons[item.iconName];
          return <Icon className={classes.icon} />;
        }
      }
    };

    return (
      <Box key={item.path}>
        <NavLink
          activeClassName={classes.linkActive}
          className={classes.link}
          key={item.path}
          to={item.path}
          onClick={() => setActive(item)}
          isActive={(match) => {
            return !!match && !(match.url.includes('admin') && !match.isExact);
          }}
        >
          <Box className={classes.accessory} />
          {Icon()}
          <Box className={classes.text}>{item.text}</Box>
        </NavLink>

        {active.path === PRODUCTS && item.path === PRODUCTS && (
          <ProductFolderComponent />
        )}
      </Box>
    );
  });

const VerticalNavigationMenuContainer: React.FunctionComponent<Props> = ({
  className,
  workspaceProducerId,
  viewerRoleIsAdmin,
  viewerRoleIsSuperadmin,
  viewerRoleIsWorkspaceAdmin,
  viewerRoleIsPanelManage,
  viewerRoleIsTextureAnalysis,
  viewerRoleIsAnalysisView,
  viewerRoleIsProductReview,
  viewerRoleIsPartnerAdmin,
}) => {
  const { t } = useTranslation();
  const classes = useStyles(useTheme());
  const environ = useGgEnvironment();

  const productMenuItem = {
    path: PRODUCTS,
    iconName: 'productSvg',
    text: t('navigation.products'),
  };

  const [active, setActive] = useState(productMenuItem);

  const primaryMenuItems = [
    ...(viewerRoleIsPanelManage ? [productMenuItem] : []),
    ...(viewerRoleIsPanelManage
      ? [{ path: PANELS, iconName: 'panelSvg', text: t('navigation.panels') }]
      : []),
    ...(viewerRoleIsWorkspaceAdmin
      ? [{ path: USERS, iconName: 'userSvg', text: t('navigation.users') }]
      : []),
    ...(viewerRoleIsAdmin || viewerRoleIsAnalysisView
      ? [
          {
            path: PROJECTS,
            iconName: 'panelSvg',
            text: t('navigation.projects'),
          },
        ]
      : []),
    ...(viewerRoleIsSuperadmin &&
    (isAfsWorkspace(workspaceProducerId) ||
      workspaceProducerId === 107 ||
      workspaceProducerId === 189 ||
      workspaceProducerId === 209)
      ? [
          {
            path: REPORTS_QA,
            iconName: 'reportSvg',
            text: t('navigation.reportsQa'),
          },
        ]
      : []),
    ...(viewerRoleIsSuperadmin
      ? [
          {
            path: SUPERADMIN_MANAGE_DEVICES,
            iconName: 'deviceSvg',
            text: t('navigation.devices'),
          },
        ]
      : []),

    ...(viewerRoleIsSuperadmin
      ? [
          {
            path: formatPath(REPORT_DASHBOARD, { reportId: null }),
            iconName: 'reportSvg',
            text: t('navigation.reportDashboard'),
          },
        ]
      : []),
    ...(viewerRoleIsSuperadmin ||
    (viewerRoleIsPartnerAdmin &&
      partnerPortal.partnerPortalReportOrder(environ))
      ? [
          {
            path: REQUEST_REPORT,
            iconName: 'reportSvg',
            text: t('reports.reportRequest'),
          },
        ]
      : []),
    ...(viewerRoleIsSuperadmin
      ? [
          {
            path: CUSTOM_LEXICON,
            iconName: 'reviewSvg',
            text: t('navigation.customLexicons'),
          },
        ]
      : []),
    // disable PQ Model Playground in prod for now
    ...(!isProd(environ) && isAfsWorkspace(workspaceProducerId)
      ? [
          {
            path: PQ_MODEL_PLAYGROUND,
            iconName: 'reviewSvg',
            text: t('navigation.modelPlayground'),
          },
        ]
      : []),
    ...(viewerRoleIsTextureAnalysis ? [] : []),
    ...(viewerRoleIsProductReview ? [] : []),
  ];

  return (
    <div className={className}>
      {renderItems(classes, primaryMenuItems, active, setActive)}
    </div>
  );
};

VerticalNavigationMenuContainer.displayName = 'VerticalNavigationMenuContainer';

export default VerticalNavigationMenuContainer;
