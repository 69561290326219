import { NonePartner } from 'constants/partner';

import { flowRight as compose } from 'lodash';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import UserCreateContainer from './UserCreateContainer';
import formSubmit from '../../actions/formSubmit';
import { CREATE_USER_FORM } from '../../constants/formNames';

const validation = (val) =>
  !val || (typeof val === 'object' && val.length === 0);
const validatePassword = (pass) => !pass || (pass && pass.length < 6);

const mapStateToProps = (_state, _props) => ({
  initialValues: {
    partner: NonePartner,
  },
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: CREATE_USER_FORM,
    onSubmit: (values, dispatch) => {
      dispatch(formSubmit(CREATE_USER_FORM));
    },
    validate: (values) => {
      return {
        username: validation(values.username),
        password: validatePassword(values.password),
        partner: validation(values.partner),
      };
    },
  }),
)(UserCreateContainer);
